<template>
<div class="warranty-info">
  <div
      class="contact-img"
      :style="bgImg"
  >
    <h2
        class="title"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
    >Support Center</h2>
  </div>
  <div class="container">
    <h2 class="section-title">WARRANTY INFO</h2>
    <img src="../assets/images/img/contintental-warranty.png" alt="img">
  </div>
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
export default {
  name: "WarrantyInfo",
  components: {Footer},
  data(){
    return{
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/support-bg.png')})`
      }
    }
  },
  mounted() {
    window.scrollTo(0,0)
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.warranty-info{
  background-color: $white;
  .contact-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding: 414px 0 77px 100px;
    .title{
      color: $white;
    }
  }
  .container{
    padding: 40px 0;
    text-align: center;
    .section-title{
      padding-bottom: 30px;
    }
  }
}
</style>